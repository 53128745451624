// Constants
import {
  CITY_RULES,
  NUMBER_RULES,
  RULES_REGEX_FLAGS,
  STREET_RULES,
  VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME,
  ZIP_RULES,
} from './constants/address-modal-inputs.utils.constants';
// Enumerations
import { ValidateStatusEnumeration } from 'components/address-modal/enums/validate-status.enumeration';
// Resolvers
import { validateCityWithPostcodeResolver } from 'components/address-modal/resolvers/address-modal.resolvers';
// Translations
import { ERROR_MESSAGES } from '../translations/address-modal-inputs.translations';
// Types
import { AddressFormValuesType } from 'components/address-modal/types/address-form-values.type';
import { GetInputRulesReturnType } from './types/get-input-rules-return.type';
import { GetInputRulesType } from './types/get-input-rules.type';
// Utils
import { debounce } from 'utils/debounce/debounce.utils';

const validateCityWithPostcode = debounce(
  async (formValues: AddressFormValuesType, onError: VoidFunction): Promise<boolean | string> => {
    const [response, error] = await validateCityWithPostcodeResolver({
      city: formValues.city,
      postCode: formValues.zip,
    });

    if (error) {
      onError();
      return false;
    }

    if (response && response.status === ValidateStatusEnumeration.FAILED && response.code) {
      return ERROR_MESSAGES.ZIP_NOT_MATCH;
    }

    return true;
  },
  VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME
);

const isEmptyValue = (value: string): boolean | string => !!value.trim() || ERROR_MESSAGES.REQUIRED;

const getInputsRules = ({
  addressDetailsValidators: { street, number, additionalItems, postalCode, city },
  onError,
}: GetInputRulesType): GetInputRulesReturnType => {
  const createPatternRule = (value: string, errorMessage: string) => ({
    value: new RegExp(value, RULES_REGEX_FLAGS),
    message: errorMessage,
  });

  return {
    street: {
      ...STREET_RULES,
      pattern: createPatternRule(street, ERROR_MESSAGES.FORMAT),
      validate: isEmptyValue,
    },
    number: {
      ...NUMBER_RULES,
      pattern: createPatternRule(number, ERROR_MESSAGES.FORMAT),
      validate: isEmptyValue,
    },
    additional: {
      pattern: createPatternRule(additionalItems, ERROR_MESSAGES.FORMAT),
    },
    zip: {
      ...ZIP_RULES,
      pattern: createPatternRule(postalCode, ERROR_MESSAGES.ZIP_FORMAT),
      validate: {
        isEmptyValue,
        validateCityWithPostcode: (_, formValues) => validateCityWithPostcode(formValues, onError),
      },
    },
    city: {
      ...CITY_RULES,
      pattern: createPatternRule(city, ERROR_MESSAGES.FORMAT),
      validate: isEmptyValue,
    },
  };
};

export { debounce, isEmptyValue, getInputsRules, validateCityWithPostcode };
