// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { QuickActionComponent } from '@openbank/cb-ui-commons';
import { ModalComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  QUICK_ACTIONS_MODAL_TEST_ID,
  QUICK_ACTIONS_MODAL_DEFAULT_COLUMNS,
  MODAL_COMPONENT_PROPS,
} from './constants/quick-actions-modal.component.constants';
// Styles
import { QuickActionsComponentStyled } from 'components/quick-actions/quick-actions.component.styled';
// Translations
import { ALL_OPTIONS } from 'components/quick-actions/translations/quick-actions.translations.common';
// Types
import { QuickActionsItemComponentType } from '../types/quick-actions-item.component.type';
import { QuickActionsModalComponentType } from './types/quick-actions-modal.component.type';

const QuickActionsModalComponent = ({
  handleQuickActionClick,
  handleClickCloseModal,
  isMobile,
  isModalOpen,
  quickActions,
  mappedQuickActions,
}: QuickActionsModalComponentType): React.ReactElement<QuickActionsModalComponentType> | null => {
  return isMobile && isModalOpen ? (
    <ModalComponent
      title={<FormattedMessageComponent id={ALL_OPTIONS} />}
      {...MODAL_COMPONENT_PROPS}
      onHeaderClose={handleClickCloseModal}
    >
      <QuickActionsComponentStyled
        columns={QUICK_ACTIONS_MODAL_DEFAULT_COLUMNS}
        data-testid={QUICK_ACTIONS_MODAL_TEST_ID}
      >
        {mappedQuickActions.map(
          ({ component, id, label, tracking, url }: QuickActionsItemComponentType, key: number) => (
            <QuickActionComponent
              {...{ ...component }}
              disabled={Boolean(quickActions[id])}
              key={key}
              label={<FormattedMessageComponent id={label} />}
              onClick={() => handleQuickActionClick({ id, tracking, url })}
            />
          )
        )}
      </QuickActionsComponentStyled>
    </ModalComponent>
  ) : null;
};

export { QuickActionsModalComponent };
