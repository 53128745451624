// Types
import { RightEventButtonClickHandlerType } from './types/right-event-button-click-handler.type';
import { LeftEventButtonClickHandlerType } from './types/left-event-button-click-handler.type';
import {
  WizardStepFooterBuilderHandlersType,
  WizardStepFooterBuilderReturnHandlersType,
} from './types/wizard-step-footer.handlers.type';

const updateRightButton =
  (target?: number) =>
  (currentStep?: number): number =>
    target || Number(currentStep) + 1;

const updateLeftButton =
  (target?: number) =>
  (currentStep?: number): number =>
    target || Number(currentStep) - 1 || 1;

const rightEventButtonClickHandler = ({
  handleRightEventButtonClickTracking,
  setCurrentStep,
  target,
}: RightEventButtonClickHandlerType): void => {
  handleRightEventButtonClickTracking();
  setCurrentStep(updateRightButton(target));
};

const leftEventButtonClickHandler = ({
  handleLeftEventButtonClickTracking,
  setCurrentStep,
  target,
}: LeftEventButtonClickHandlerType): void => {
  handleLeftEventButtonClickTracking();
  setCurrentStep(updateLeftButton(target));
};

const updateModalVisibility =
  () =>
  (prevVisibility: boolean): boolean =>
    !prevVisibility;

const toggleCancelFlowModalEventHandler = ({
  handleToggleCancelFlowModalLinkClickTracking,
  setShowCancelModal,
}: Pick<
  WizardStepFooterBuilderHandlersType,
  'handleToggleCancelFlowModalLinkClickTracking' | 'setShowCancelModal'
>): void => {
  handleToggleCancelFlowModalLinkClickTracking();
  setShowCancelModal(updateModalVisibility());
};

const WizardStepFooterHandlers = ({
  handleRightEventButtonClickTracking,
  handleLeftEventButtonClickTracking,
  handleToggleCancelFlowModalLinkClickTracking,
  setCurrentStep,
  setShowCancelModal,
  targets,
}: WizardStepFooterBuilderHandlersType): WizardStepFooterBuilderReturnHandlersType => ({
  handleRightEventButtonClick: () =>
    rightEventButtonClickHandler({
      handleRightEventButtonClickTracking,
      setCurrentStep,
      target: targets?.next,
    }),
  handleLeftEventButtonClick: () =>
    leftEventButtonClickHandler({
      handleLeftEventButtonClickTracking,
      setCurrentStep,
      target: targets?.previous,
    }),
  handleToggleCancelFlowModalLinkClick: () =>
    toggleCancelFlowModalEventHandler({
      handleToggleCancelFlowModalLinkClickTracking,
      setShowCancelModal,
    }),
});

export default WizardStepFooterHandlers;
