// Vendors
import { useEffect, useState } from 'react';
// Constants
import { SHOW_MODAL_DEFAULT_VALUE } from '../constants/wizard-step-footer.constants';
// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Event handlers
import WizardStepFooterHandlers from '../handlers/wizard-step-footer.handlers';
import WizardStepFooterTrackingHandlers from '../handlers/wizard-step-footer.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { WizardStepFooterHookReturnType } from './types/wizard-step-footer-return.hook.type';
import { WizardStepFooterHookType } from './types/wizard-step-footer.hook.type';

const WizardStepFooterHook = ({
  setShowModal,
  showModal = SHOW_MODAL_DEFAULT_VALUE,
  targets,
}: WizardStepFooterHookType): WizardStepFooterHookReturnType => {
  const { formatMessage } = useCbIntl();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(showModal);

  useEffect(() => setShowCancelModal(showModal), [showModal]);

  const { setCurrentStep } = useWizardContextConsumerHook();

  return {
    ...WizardStepFooterHandlers({
      ...AppTrackingHook(WizardStepFooterTrackingHandlers),
      setCurrentStep,
      setShowCancelModal: setShowModal || setShowCancelModal,
      targets,
    }),
    formatMessage,
    showCancelModal,
  };
};

export default WizardStepFooterHook;
