// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Constants
import {
  WIZARD_STEP_FOOTER_CANCEL_MODAL_BUTTON_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_LINK_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_ASSET_PROPS,
} from 'components/wizard-step-footer/components/cancel-modal/constants/wizard-step-footer-cancel-modal.constants';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { WizardStepFooterCancelModalContentComponent } from './components/wizard-step-footer-cancel-modal-content.component';
// Hooks
import useWizardStepFooterCancelModalHook from 'components/wizard-step-footer/components/cancel-modal/hooks/wizard-step-footer-cancel-modal.hook';
// Styles
import {
  WizardStepFooterFeedbackCancelModalAssetComponentStyled,
  WizardStepFooterCancelModalComponentStyled,
} from './wizard-step-footer-cancel-modal.component.styled';
// Translations
import * as translations from 'components/wizard-step-footer/components/cancel-modal/translations/wizard-step-footer-cancel-modal.translations';
// Types
import { WizardStepFooterCancelModalComponentType } from 'components/wizard-step-footer/components/cancel-modal/types/wizard-step-footer-cancel-modal.component.type';

const WizardStepFooterCancelModalComponent = ({
  showModal,
  cancelModalTranslations,
  disabledButtonsCancelModal,
  ...rest
}: WizardStepFooterCancelModalComponentType): React.ReactElement | null => {
  const {
    handleConfirmToContinueProcessButtonClick,
    handleCloseWizardStepFooterCancelProcessModalAssetClick,
    handleCancelExitProcessLinkClick,
  } = useWizardStepFooterCancelModalHook({ ...rest, showModal });

  const {
    button = translations.BUTTON,
    link = translations.LINK,
    title,
  } = cancelModalTranslations || {};

  return showModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...WIZARD_STEP_FOOTER_CANCEL_MODAL_LINK_PROPS,
        disabled: disabledButtonsCancelModal,
        children: <FormattedMessageComponent id={link} />,
        onClick: handleCancelExitProcessLinkClick,
      }}
      primaryButtonConfiguration={{
        ...WIZARD_STEP_FOOTER_CANCEL_MODAL_BUTTON_PROPS,
        disabled: disabledButtonsCancelModal,
        children: <FormattedMessageComponent id={button} />,
        onClick: handleConfirmToContinueProcessButtonClick,
      }}
      onHeaderClose={
        disabledButtonsCancelModal
          ? () => undefined
          : handleCloseWizardStepFooterCancelProcessModalAssetClick
      }
      title={title && <FormattedMessageComponent id={translations.TITLE} />}
    >
      <WizardStepFooterCancelModalComponentStyled>
        <WizardStepFooterFeedbackCancelModalAssetComponentStyled>
          <AssetComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_ASSET_PROPS} />
        </WizardStepFooterFeedbackCancelModalAssetComponentStyled>
        <WizardStepFooterCancelModalContentComponent {...{ cancelModalTranslations }} />
      </WizardStepFooterCancelModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export default WizardStepFooterCancelModalComponent;
